import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import '../../App.css';
import '../AboutMe.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AboutMe() {
  const timelineData = [
    {
      title: 'The Start of ChhengComputers',
      date: 'Summer of 2022',
      imgSrc: '/images/start-1.jpg',
      details:
        'One day after I was going through things to sell before college and thought that I could make ways with my computer. Right before going through the door, I dropped it, shattering in millions of pieces. Having called the manufacturer, the only solution was to build it again.',
    },
    {
      title: 'My First Computer',
      date: 'Mid Summer of 2022',
      imgSrc: '/images/start-2.jpg',
      details:
        'Weeks after my computer was sold, I thought it would be much more exciting to build a new computer since I still needed it for school and a few of my games that I used to play. So then, I researched more about each individual part and began to build my first ever computer.',
    },
    {
      title: 'Mixing it up',
      date: 'Mid Summer of 2022',
      imgSrc: '/images/first-1.jpg',
      details:
        'Then at some point, I looked back at my computer and realized that I could build something much better for much cheaper. I ended up finding a guy on Facebook Marketplace and selling it to him for about $1400. I was excited to say that it was my first ever real sell.',
    },
    {
      title: 'What it is today',
      date: 'July 24',
      imgSrc: '/images/Screenshot 2024-09-11 at 7.58.17 PM.png',
      details:
        'After that moment, I began to sell about 50 more computers and started to scale the business to students at UT Dallas and buyers on Facebook marketplace. In doing so, I thought it would be in my best interest to build a website to host my services and help to others in need.',
    },
  ];

  const NextArrow = (props) => {
    const { onClick } = props;
    return <div className="arrow next" onClick={onClick}>›</div>;
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return <div className="arrow prev" onClick={onClick}>‹</div>;
  };

  const settings = {
    centerMode: true,
    centerPadding: "100px",
    slidesToShow: 1,
    infinite: true,
    swipeToSlide: true,
    speed: 700,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const scrollToCarousel = () => {
    const carouselElement = document.querySelector('.carousel-container');
    if (carouselElement) {
      carouselElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <motion.div
      className="aboutme-container"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="aboutme-header">about me</h1>
      <div className="aboutme-content">
        <img
          src="images/Michael_Chheng_Headshot .jpg"
          alt="Michael Chheng"
          className="my-photo"
        />
        <div className="aboutme-info">
          <h2>michael chheng</h2>
          <p>
            Hi, I'm Michael, born and raised in Fort Worth, TX. I am currently a
            senior at UT Dallas studying Business Analytics and Artificial
            Intelligence and started this passion to help serve those in college in need of affordable tech solutions.
          </p>
        </div>
      </div>
      <h2 className="timeline-container">read more about my journey</h2>
      <div className="arrow2" onClick={scrollToCarousel}>↓</div>
      <Slider {...settings} className="carousel-container">
        {timelineData.map((item, index) => (
          <motion.div
            key={index}
            className="carousel-item"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.5 }}
          >
            <div className="timeline-box">
              <img
                src={item.imgSrc}
                alt={item.title}
                className="timeline-image"
              />
              <h3>{item.title}</h3>
              <p className="timeline-date">{item.date}</p>
              <p className="timeline-details">{item.details}</p>
            </div>
          </motion.div>
        ))}
      </Slider>
    </motion.div>
  );
}

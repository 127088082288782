import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '../../App.css';
import '../Computers.css';

export default function Computers() {
  const [selectedComputer, setSelectedComputer] = useState(null);

  const computersData = [
    {
      title: 'most recent computer',
      imgSrc: '/images/most_recent_computer.jpg',
      description: 'take a look at my most recent computer',
      details: {
        CPU: 'AMD Ryzen 5 5600x',
        PSU: '750W Gold EVGA',
        GPU: '3070Ti ASUS ROG Strix',
        STORAGE: '1TB Samsung 970 EVO PLUS',
        RAM: '32GB Corsair CL18',
        CASE: 'LIAN LI Vision 011',
        COOLER: 'LIAN LI Galahad II',
      },
      saved: 300,
      soldFor: 1300,
    },
    {
      title: 'intel i5 12600k build',
      imgSrc: '/images/IMG_2744 2.jpg',
      description: 'one of my most popular builds for an insanely cheap price',
      details: {
        CPU: 'Intel i5 12600k',
        PSU: '650W Gold EVGA',
        GPU: 'MSI 3060Ti',
        STORAGE: '1TB Samsung 970 EVO PLUS',
        RAM: '16GB',
        CASE: 'NZXT H9 Flow',
        COOLER: 'LIAN LI Galahad I',
      },
      soldFor: 1300,
      saved: 200,
    },
    {
      title: 'the pinterest post',
      imgSrc: '/images/IMG_2668_2.jpg',
      description: 'smooth and aesthetically pleasing all white powered machine',
      details: {
        CPU: 'AMD Ryzen 5 5600x',
        PSU: '750W Gold EVGA',
        GPU: '3070 Gigabyte Vision',
        STORAGE: '1TB Samsung 970 EVO PLUS',
        RAM: '16GB',
        CASE: 'NZXT X53 White',
        COOLER: 'NZXT KRAKEN AIO 280mm',
      },
      soldFor: 900,
      saved: 300,
    },
    {
      title: 'perfect college build',
      imgSrc: '/images/IMG_3946 2.jpg',
      description: 'cheap budget build where power meets affordability',
      details: {
        CPU: 'AMD Ryzen 5 5600x',
        PSU: '650W Gold EVGA',
        GPU: 'AMD 6650XT',
        STORAGE: '1Gb SSD',
        RAM: '16GB',
        CASE: 'Corsair 4000D',
        COOLER: 'AMD Stock Cooler',
      },
      soldFor: 800,
      saved: 350,
    },
    {
      title: 'all black with custom AIO',
      imgSrc: '/images/IMG_6093.jpg',
      description: 'sleek black design with a custom LCD display',
      details: {
        CPU: 'Intel i9 10900K',
        PSU: '750W Gold',
        GPU: 'RTX 4060ti',
        STORAGE: '2TB Samsung 970 EVO PLUS',
        RAM: '32GB Corsair Dominator',
        CASE: 'NZXT H9 Flow',
        COOLER: 'NZXT Kraken',
      },
      soldFor: 1100,
      saved: 400,
    },
    {
      title: 'small pink build',
      imgSrc: '/images/IMG_6014.jpg',
      description: 'cute pink compact computer',
      details: {
        CPU: 'Intel i5 10400',
        PSU: '550W Bronze',
        GPU: 'GTX 1660 Ti',
        STORAGE: '1TB SSD',
        RAM: '16GB',
        CASE: 'InWin A1 Mini ITX',
        COOLER: 'Noctua NH-L9i',
      },
      soldFor: 700,
      saved: 200,
    },
    {
      title: 'classic, affordable, functional',
      imgSrc: '/images/IMG_3371 2.jpg',
      description: 'affordable build with reliable performance',
      details: {
        CPU: 'Intel i3 10100F',
        PSU: '450W Bronze',
        GPU: 'GTX 1650',
        STORAGE: '500GB SSD',
        RAM: '8GB',
        CASE: 'Cooler Master MasterBox Q300L',
        COOLER: 'Stock Cooler',
      },
      soldFor: 750,
      saved: 350,
    },
  ];

  const closeModal = () => setSelectedComputer(null);

  return (
    <motion.div
      className="computers-container"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="computers-title">computers</h1>
      <div className="computers-content">
        <div className="computer-cards">
          {computersData.map((computer, index) => (
            <motion.div
              key={index}
              className="computer-card"
              onClick={() => setSelectedComputer(computer)}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="sold-price">{`saved $${computer.saved}`}</div>
              <img src={computer.imgSrc} alt={computer.title} className="computer-image" />
              <h4>{computer.title}</h4>
              <p className="computer-description">{computer.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedComputer && (
        <div className="modal-overlay" onClick={closeModal}>
          <motion.div
            className="modal-content expanded"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-btn" onClick={closeModal}>
              &times;
            </button>
            <div className="modal-layout">
              <img src={selectedComputer.imgSrc} alt={selectedComputer.title} className="modal-image" />
              <div className="modal-details">
                <h3 className="sold-price-modal">{`sold for $${selectedComputer.soldFor}`}</h3>
                <h2>{selectedComputer.title}</h2>
                {Object.entries(selectedComputer.details).map(([key, value]) => (
                  <p key={key}>
                    <span className="spec-label">{key}:</span> {value}
                  </p>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </motion.div>
  );
}

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from './components/Pages/Home';
import Services from './components/Pages/Services';
import Computers from './components/Pages/Computers';
import AboutMe from './components/Pages/AboutMe';
import Reviews from './components/Pages/Reviews';
import ContactMe from './components/Pages/ContactMe';
import Guidebook from './components/Pages/Guidebook';
import Payment from './components/Pages/Payment';
import Navbar from './components/Navbar';
import './App.css';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/computers" element={<Computers />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contactme" element={<ContactMe />} />
        <Route path="/guidebook" element={<Guidebook />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <AnimatedRoutes />
    </Router>
  );
}

export default App;

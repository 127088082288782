import React, { useState } from 'react';
import { motion } from 'framer-motion';
import StripeContainer from '../../components/StripeContainer';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { saveAs } from 'file-saver';
import '../../App.css';
import '../Payment.css';

const Payment = () => {
  const [searchMethod, setSearchMethod] = useState('ticket');
  const [ticketNumber, setTicketNumber] = useState('');
  const [email, setEmail] = useState('');
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceError, setInvoiceError] = useState(false);

  const handleSearchMethodChange = (method) => {
    setSearchMethod(method);
    setTicketNumber('');
    setEmail('');
    setInvoiceData(null);
    setInvoiceError(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'ticketNumber') {
      setTicketNumber(value);
    } else if (name === 'email') {
      setEmail(value);
    }
    setInvoiceData(null);
    setInvoiceError(false);
  };

  const checkInvoice = async () => {
    try {
      let q;
      if (searchMethod === 'ticket') {
        q = query(
          collection(db, 'tickets'),
          where('ticketNumber', '==', parseInt(ticketNumber))
        );
      } else {
        q = query(
          collection(db, 'tickets'),
          where('user_email', '==', email)
        );
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setInvoiceData(null);
        setInvoiceError(true);
      } else {
        const ticketData = querySnapshot.docs[0].data();
        setInvoiceData(ticketData);
        setInvoiceError(false);
      }
    } catch (error) {
      console.error('Error searching for ticket: ', error);
      setInvoiceError(true);
    }
  };

  const downloadExcel = async () => {
    if (!invoiceData || !invoiceData.invoiceFileUrl) {
      alert('No invoice available to download.');
      return;
    }
  
    try {
      // Use the Public URL directly
      const url = invoiceData.invoiceFileUrl; // This should now point to the public URL
      saveAs(url, `${ticketNumber}_invoice.xlsx`);
      console.log('Download started for:', url);
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      alert('Unable to download invoice. Please try again later.');
    }
  };
  

  return (
    <motion.div
      className="payment-container"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <h1>payment portal</h1>
      <p>please enter your ticket number or email to view your invoice and proceed with payment.</p>

      <div className="search-method">
        <button
          onClick={() => handleSearchMethodChange('ticket')}
          className={searchMethod === 'ticket' ? 'active' : ''}
        >
          search by ticket number
        </button>
        <button
          onClick={() => handleSearchMethodChange('email')}
          className={searchMethod === 'email' ? 'active' : ''}
        >
          search by email
        </button>
      </div>

      <div className="ticket-check-form">
        {searchMethod === 'ticket' ? (
          <>
            <label>ticket number</label>
            <input
              type="text"
              name="ticketNumber"
              placeholder="enter your ticket number"
              value={ticketNumber}
              onChange={handleInputChange}
            />
          </>
        ) : (
          <>
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="enter your email"
              value={email}
              onChange={handleInputChange}
            />
          </>
        )}
        <button onClick={checkInvoice}>check invoice</button>

        {invoiceError && (
          <p className="invoice-error">
            no invoice found for this {searchMethod}. please contact support for assistance.
          </p>
        )}
      </div>

      {invoiceData && (
        <motion.div
          className="invoice-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>your invoice</h2>
          <p className="total-amount">
            <strong>Total Amount:</strong> ${invoiceData.amount}
          </p>
          <div className="invoice-details">
            <p>
              <strong>Name:</strong> {invoiceData.user_name}
            </p>
            <p>
              <strong>Email:</strong> {invoiceData.user_email}
            </p>
            <p>
              <strong>Phone:</strong> {invoiceData.user_phone}
            </p>
            <p>
              <strong>Price Range:</strong> {invoiceData.price_range}
            </p>
            <p>
              <strong>Questions:</strong> {invoiceData.user_inquiries}
            </p>
            <p>
              <strong>PC Part Picker List:</strong> {invoiceData.user_pcpartpicketlist}
            </p>
          </div>

          <h3>billing information</h3>
          <div className="billing-details">
            <div className="form-group">
              <label>Name on Card</label>
              <input type="text" placeholder="Name on Card" required />
            </div>
            <div className="form-group">
              <label>Billing Address</label>
              <input type="text" placeholder="Street Address" required />
              <input type="text" placeholder="City" required />
              <input type="text" placeholder="State/Province" required />
              <input type="text" placeholder="Postal Code" required />
            </div>
          </div>

          <div className="stripe-container">
            <h3>proceed with payment</h3>
            <StripeContainer invoiceData={invoiceData} />
          </div>

          <button className="download-btn" onClick={downloadExcel}>
            <h4>use your ticket number</h4>
            download invoice
          </button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default Payment;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../App.css';
import '../ContactMe.css';
import { db } from '../../firebaseConfig'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore functions
import { firebaseConfig } from '../../firebaseConfig'; // Import firebase config to access storageBucket

export default function ContactMe() {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    price_range: '',
    user_inquiries: '',
    user_pcpartpicketlist: '',
  });

  const [submitStatus, setSubmitStatus] = useState(null); // To track submission status
  const [ticketNumber, setTicketNumber] = useState(null); // To track the generated ticket number

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Generate a random ticket number
    const generatedTicketNumber = Math.floor(Math.random() * 1000000);

    // Generate the invoice file URL using Google Storage
    const invoiceFileUrl = `https://storage.googleapis.com/${firebaseConfig.storageBucket}/invoices/${generatedTicketNumber}.xlsx`;

    try {
      // Send email using EmailJS
      const emailTemplateParams = {
        user_name: formData.user_name,
        user_email: formData.user_email,
        user_phone: formData.user_phone,
        price_range: formData.price_range,
        user_inquiries: formData.user_inquiries || 'None',
        user_pcpartpicketlist: formData.user_pcpartpicketlist || 'None',
        ticket_number: generatedTicketNumber,
        invoice_url: invoiceFileUrl,
      };

      await emailjs.send(
        'service_tcfbicf', // Replace with your EmailJS service ID
        'template_3fex13w', // Replace with your EmailJS template ID
        emailTemplateParams,
        'ubZigxt0DQmmFuq7T' // Replace with your EmailJS user ID
      );

      // Save the ticket to Firestore
      const docRef = await addDoc(collection(db, 'tickets'), {
        ticketNumber: generatedTicketNumber,
        ...formData,
        invoiceFileUrl, // Add invoice URL to Firestore
        timestamp: new Date(), // Add timestamp
      });

      console.log('Document written with ID: ', docRef.id);
      setSubmitStatus('Message sent successfully! A copy of your ticket details has been emailed to you.');
      setTicketNumber(generatedTicketNumber); // Set the generated ticket number
      e.target.reset(); // Reset form fields after submission
    } catch (error) {
      console.error('Error adding document or sending email: ', error);
      setSubmitStatus('Failed to send message. Please try again.');
    }

    // Reset the form data in the state
    setFormData({
      user_name: '',
      user_email: '',
      user_phone: '',
      price_range: '',
      user_inquiries: '',
      user_pcpartpicketlist: '',
    });
  };

  return (
    <div className="contactme">
      <form onSubmit={handleSubmit} className="contact-form">
        <h1 className="contactme-header">contact me</h1>
        <div className="form-group">
          <label htmlFor="user_name">name *</label>
          <input
            type="text"
            id="user_name"
            name="user_name"
            value={formData.user_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="user_email">email *</label>
          <input
            type="email"
            id="user_email"
            name="user_email"
            value={formData.user_email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="user_phone">phone number *</label>
          <input
            type="text"
            id="user_phone"
            name="user_phone"
            value={formData.user_phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="price_range">price range *</label>
          <select
            id="price_range"
            name="price_range"
            value={formData.price_range}
            onChange={handleChange}
            required
          >
            <option value="">select a price range</option>
            <option value="<500">&lt;500</option>
            <option value="<1000">&lt;1000</option>
            <option value="<1500">&lt;1500</option>
            <option value=">1500">&gt;1500</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="user_questions">questions</label>
          <textarea
            id="user_questions"
            name="user_questions"
            value={formData.user_questions}
            onChange={handleChange}
            placeholder="(Optional)"
          />
        </div>
        <div className="form-group">
          <label htmlFor="user_pcpartpicketlist">part picker list link</label>
          <textarea
            id="user_pcpartpicketlist"
            name="user_pcpartpicketlist"
            value={formData.user_pcpartpicketlist}
            onChange={handleChange}
            placeholder="(Optional)"
          />
        </div>
        <button type="submit" className="submit-btn">submit</button>

        {/* Display the submission status and ticket number below the form */}
        {submitStatus && (
          <div className="status-container">
            <p className="submit-status">{submitStatus}</p>
            {ticketNumber && (
              <p className="ticket-number">
                your ticket number: {ticketNumber} <br />
              </p>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

// src/components/StripeContainer.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from './StripePaymentForm';

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_live_51Q4vk7DKeNB8QMB0t54atJ6QpUN81LcIKD7jJUBdnPT6u5iDbFX0BjlRWi96GeJ24O0Qm56luKI8ZCYxOOLHGqsG005BaJCv5p');

const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripePaymentForm />
    </Elements>
  );
};

export default StripeContainer;

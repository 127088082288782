import React, { useState } from "react";
import { motion } from "framer-motion";
import "../../App.css";
import "../Services.css";
import { Link } from "react-router-dom";

export default function Services() {
  const [expandedService, setExpandedService] = useState(null);

  const handleExpandClick = (service) => {
    setExpandedService(expandedService === service ? null : service);
  };

  const servicesData = [
    {
      title: "custom builds",
      details: "for our custom builds, it costs $75 to build the computer for labor and a free consultation with a $50 warranty fee up to 30 days",
    },
    {
      title: "laptop repair",
      details:
        "casually fix your laptop's screen, storage amount, or replace your battery for only $50",
    },
    {
      title: "consultations",
      details:
        "i am available to consult with you on the best options for your tech needs, ensuring you make informed decisions with excel dashboards",
    },
  ];

  return (
    <motion.div
      className="services-container"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="services-title">services</h1>

      {/* Stats Section */}
      <div className="stats-section">
        <h2 className="stats-title">the stats</h2>
        <div className="stats-content">
          <motion.div
            className="stat"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h3>30%</h3>
            <p>savings on average compared to prebuilt systems</p>
          </motion.div>
          <motion.div
            className="stat"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3>50%</h3>
            <p>better performance with custom builds</p>
          </motion.div>
          <motion.div
            className="stat"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3>100%</h3>
            <p>customer satisfaction guaranteed</p>
          </motion.div>
        </div>
      </div>

      {/* Main Description Section */}
      <div className="description-section horizontal-layout">
        {/* Image above the text */}
        <img
          src="/images/IMG_2163.jpg"
          alt="Michael Chheng"
          className="services-image"
        />
        <div className="text-content">
          <h2 className="computers-laptops-title">computers | laptops</h2>
          <p className="description-text">
            my journey selling computers is a passion. i've realized that it's
            very difficult nowadays to find a computer at a good price while
            purchasing a customizable/personalized tower. often times, PCs that
            are bought are poorly optimized, and you can potentially overspend
            by about $200-$300 without even knowing it. our services extend to a
            variety of different offerings, but the core offering resides in
            getting the best deal for the people, as there's no reason to spend
            more money than you need to.
          </p>
        </div>
      </div>

      {/* Right Side: Service Dropdowns */}
      <div className="services-dropdown">
        <h2 className="services-title">our services</h2>
        {servicesData.map((service, index) => (
          <div key={index} className="service-item">
            <div
              className="service-title"
              onClick={() => handleExpandClick(service.title)}
            >
              <h4>{service.title}</h4>
              <span>{expandedService === service.title ? "▲" : "▼"}</span>
            </div>
            {expandedService === service.title && (
              <motion.div
                className="service-details"
                layout
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <p>{service.details}</p>
                <Link to="/contactme" className="contact-btn">
                  contact me
                </Link>
              </motion.div>
            )}
          </div>
        ))}
      </div>

      {/* Video Section */}
      <h3 className="stats-title">
        for more information, click the video below{" "}
      </h3>
      <div className="video-section">
        <div className="video-wrapper">
          <iframe
            className="services-video"
            src="https://www.youtube.com/embed/ZIvN3sIoMY0"
            title="Service Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </motion.div>
  );
}

import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
    return () => window.removeEventListener('resize', showButton);
  }, []);

  return (
    <nav className='navbar'>
      <div className='navbar-container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src='/images/chheng logo.png' alt='Chheng Computers Logo' className='navbar-logo-img' />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
              services
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/computers' className='nav-links' onClick={closeMobileMenu}>
              computers
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/aboutme' className='nav-links' onClick={closeMobileMenu}>
              about me
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/reviews' className='nav-links' onClick={closeMobileMenu}>
              reviews
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/guidebook' className='nav-links' onClick={closeMobileMenu}>
              guidebook
            </Link>
          </li>
          <li className='nav-item mobile-only'>
            <Link to='/contactme' className='nav-links' onClick={closeMobileMenu}>
              contact me
            </Link>
          </li>
          <li className='nav-item mobile-only'>
            <Link to='/payment' className='nav-links' onClick={closeMobileMenu}>
              payment
            </Link>
          </li>
        </ul>
        <div className='nav-buttons'>
          {button && (
            <>
              <Button buttonStyle='btn--outline' linkTo='/contactme'>
                contact me
              </Button>
              <Button buttonStyle='btn--primary' linkTo='/payment'>
                payment
              </Button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

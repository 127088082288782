// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"; // Import storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCeviRIF61XewfGwVkpzCkACvF8JRpTQn4",
  authDomain: "website-c1f82.firebaseapp.com",
  projectId: "website-c1f82",
  storageBucket: "website-c1f82.appspot.com",
  messagingSenderId: "877460778129",
  appId: "1:877460778129:web:d5e5e218ae9333c27ee330",
  measurementId: "G-V44XPG21MY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

// Initialize Analytics (optional)
const analytics = getAnalytics(app);

// Export the firebaseConfig along with db, storage, and analytics
export { firebaseConfig, db, storage, analytics };
